import { useLocation } from '@remix-run/react'
import { RudderAnalytics } from '@rudderstack/analytics-js'
import { useEffect, useMemo } from 'react'
import { RUDDERSTACK_DATAPLANE_URL, RUDDERSTACK_WRITE_KEY } from '~/config'

export const Rudderstack = () => {
  const rudderAnalytics = useMemo(() => {
    const rudder = new RudderAnalytics()
    rudder.load(RUDDERSTACK_WRITE_KEY, RUDDERSTACK_DATAPLANE_URL)
    return rudder
  }, [])
  const location = useLocation()
  useEffect(() => {
    rudderAnalytics.page() // record page view
  }, [location, rudderAnalytics])
  return null
}
