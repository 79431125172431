import { Outlet, useLoaderData } from '@remix-run/react'
import { GoogleTagManager } from './components/google-tag-manager'
import { CANONICAL_HOSTNAME, GA_TRACKING_ID } from '~/config'
import type { LoaderFunctionArgs } from '@remix-run/node'
import { json, redirect } from '@remix-run/node'
import { useIsClient } from 'usehooks-ts'
import { isProduction } from './utils/is-production.server'
import { rootLinks } from './components-v2/links'
import { Rudderstack } from './components/rudderstack'
import { SharedLayout } from './components/shared-layout'
import { SupportedLanguages } from './config/i18n'
import { getFeatureFlags } from './utils/feature-flags.server'
import {
  computeNextCookieData,
  cookieExpires,
  omniCookie,
  TrackingData,
} from './services/cookie.server'
import { withSentry } from '@sentry/remix'

export { ErrorBoundary } from '~/components'
export { meta } from '~/utils'

export const links = () => [
  ...rootLinks(),
  { rel: 'icon', href: '/favicon.png', type: 'image/png' },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;500;600&display=swap',
  },
]

const canonicalRedirect = (uri: string) =>
  redirect(`https://${CANONICAL_HOSTNAME}${uri}`, 301)

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url)
  if (isProduction(request)) {
    if (url.hostname !== CANONICAL_HOSTNAME)
      return canonicalRedirect(`${url.pathname}?${url.search.toString()}`)
  }

  const { hasChanges, tracking } = await computeNextCookieData({ request })
  const expires = cookieExpires()
  return json(
    {
      /** @deprecated - remove soon */
      flash: undefined,
      isDevelopment: process.env.NODE_ENV === 'development',
      lang:
        url.pathname.split('/')[1] === 'fr'
          ? SupportedLanguages.French
          : SupportedLanguages.English,
      referrer: request.headers.get('Referer'),
      tracking,
      FEATURES: getFeatureFlags(),
    },
    {
      headers: hasChanges
        ? { 'Set-Cookie': await omniCookie.serialize(tracking, { expires }) }
        : undefined,
    },
  )
}

export type RootOutletContext = {
  tracking: TrackingData
}

export function App() {
  const { isDevelopment, lang, referrer, tracking } =
    useLoaderData<typeof loader>()
  const isClient = useIsClient()

  return (
    <SharedLayout lang={lang}>
      <script
        async
        src={`https://www.googletagmanager.com/gtm.js?id=${GA_TRACKING_ID}`}
      ></script>
      {!isDevelopment && isClient && (
        <>
          <Rudderstack />
          <GoogleTagManager referrer={referrer} tracking={tracking} />
        </>
      )}
      <Outlet context={{ tracking }} />
    </SharedLayout>
  )
}

export default withSentry(App)
