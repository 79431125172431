import type { LinksFunction } from '@remix-run/node'

import reset from '~/styles/reset.css?url'
import variables from '~/styles/variables.css?url'
import page from '~/styles/page.css?url'
import error from '~/styles/pages/error.css?url'

// Core site layout
import header from './header/header.css?url'
import disclosure from '../components/disclosure/disclosure.css?url'
import footer from './footer/footer.css?url'
import flash from './flash/flash.css?url'

const globalStyles: LinksFunction = () => [
  { rel: 'stylesheet', href: variables },
  { rel: 'stylesheet', href: reset },
  { rel: 'stylesheet', href: page },
]

/**
 * Components user across the site broadly and needed to accomplish
 * our unified site layout. These should be broadly imported by the
 * root loader
 */
export const rootLinks: LinksFunction = () => [
  ...globalStyles(),
  { rel: 'stylesheet', href: error },
  { rel: 'stylesheet', href: flash },
  { rel: 'stylesheet', href: footer },
  { rel: 'stylesheet', href: disclosure },
  { rel: 'stylesheet', href: header },
]
