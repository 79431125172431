import { useLocation } from '@remix-run/react'
import { useEffect, useState } from 'react'
import { CANONICAL_HOSTNAME, GA_TRACKING_ID } from '~/config'
import { TrackingData } from '~/services/cookie.server'

type Props = {
  referrer: string | null
  tracking: TrackingData
}

declare const window: Window & { dataLayer?: Record<string, unknown>[] }

export const GoogleTagManager = ({ referrer, tracking }: Props) => {
  const [lastPathname, setLastPathname] = useState<string>()
  const isInitialized = !!lastPathname

  const { hash, pathname, search } = useLocation()
  const page_url = `https://${CANONICAL_HOSTNAME}${pathname}${search}${hash}`
  const base = { ...tracking, page_url, referrer }

  const initializeGTM = () => {
    const dataLayer = window.dataLayer
    dataLayer!.push({ ...base, event: 'pageview' })
    dataLayer!.push({ 'gtm.start': new Date(), event: 'gtm.js' })
    dataLayer!.push({ config: GA_TRACKING_ID })
  }

  /**
   * If window.dataLayer is not yet available, we'll wait a bit and try again.
   */
  const waitForGTM = () =>
    setTimeout(() => {
      console.debug('Timeout resolved... checking for dataLayer again')

      if (window.dataLayer) {
        initializeGTM()
      } else {
        console.debug('Data layer still not available, cycle again')
        waitForGTM()
      }
    }, 1000)

  /**
   * This is the initial page view fire.
   */
  useEffect(() => {
    if (isInitialized) return
    if (pathname === lastPathname) return
    setLastPathname(pathname)

    if (window.dataLayer) {
      initializeGTM()
    } else {
      waitForGTM()
    }
  }, [])

  /**
   * Fired on "virtual" page view (navigation within existing SPA-loaded page)
   */
  useEffect(() => {
    if (!isInitialized) return
    if (pathname === lastPathname) return
    if (!window.dataLayer) window.dataLayer = []

    setLastPathname(pathname)
    window.dataLayer.push({ config: GA_TRACKING_ID })
    window.dataLayer.push({ ...base, event: 'virtual_pageview' })
  }, [pathname])

  return null
}
